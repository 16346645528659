import type { NextPage } from "next";
import React from "react";
import { Flex } from "@chakra-ui/react";
import { MainLayout } from "~/layout";
import {
	AboutUsSection,
	StepByStepSection,
	SustainableCycleSection,
	ImperativeSection,
	KnowMoreSection,
	IntroSection,
} from "../components";

const Home: NextPage = () => (
	<MainLayout disableNavbarGradient>
		<Flex color="primary.500" mx="auto" justifyContent="center" direction="column">
			<IntroSection />
			<SustainableCycleSection />
			<KnowMoreSection />
			<AboutUsSection />
			<ImperativeSection />
			<StepByStepSection mt={5} />
		</Flex>

	</MainLayout>
);

export default Home;
